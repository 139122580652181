@media screen and (max-width: 960px) and (min-width: 480px) {
  .dm__storeName {
    display: inline;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .dm__dashboard {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 6;
  }
  .dashboard__grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    width: 100%;
  }

  .dashboard-container {
    display: flex;
  }

  .dm__filterButton {
    display: none;
  }

  .dm__filter-button-container {
    display: none;
  }

  .filter-modal {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  #device-table-header,
  .dm__desktop {
    display: none;
  }
  .ln-o-container.ln-o-container--soft {
    padding: 0;
  }
  h3 {
    margin-bottom: 0.75rem;
  }

  .dm__deviceModel {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 22px;
  }

  .dm__mobileTag {
    font-weight: bold;
  }
  .dm__title {
    padding-left: 1.5rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    display: grid;
  }
  .dashboard__grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
  }
  .dashboard-container {
    display: flex;
  }

  .dm__filterButton {
    display: none;
  }

  // .dm__filter-button-container {
  //   display: none;
  // }

  // .filter-modal {
  //   display: none;
  // }

  .dm__pills {
    width: 100%;
  }
  .dm__filter-header {
    display: inline-block;
  }
  .dm__filter-button-container {
    padding-left: 1.2rem;
    display: inline-block;
    padding-bottom: 1rem;
  }

  .filter-modal {
    display: block;
  }

  .dm__filter-button {
    min-height: 28px;
    min-width: 2px;
    padding: 7px 9px 7px 9px;
    border-radius: 10px;
    border: 0.01rem solid;
    border-color: black;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Trebuchet MS', sans-serif;
    background-color: white;
    color: black;
  }

  .dm__filterPanel {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .dm__storeName {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .dm__dashboard {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 9;
  }
  .dashboard__grid {
    display: grid;
    grid-template-columns: 1;
    grid-gap: 5px;
  }
  .dm__title {
    padding-left: 1.5rem;
  }
}
@media screen and (min-width: 720px) and (max-width: 960px) {
  .ln-c-header__item--logo {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 960px) {
  .ln-c-table__header {
    outline-offset: -8px;
    box-shadow: 0 0 3px #ddd;
  }
  .dm__mobileTag,
  .dm__mobile {
    display: none;
  }
  .dashboard__grid {
    top: 100px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    margin-left: auto;
    margin-right: auto;
    //margin-top: 30px;
  }

  .dashboard-container {
    display: flex;
  }

  .dm__filterPanel {
    display: 'flex';
    flex-direction: 'column';
    margin-top: '-55px';
    float: 'left';
    position: 'relative';
    width: '30%';
    flex-grow: '1';
  }
  .dm__filter-button-container {
    display: none;
  }

  .dm__title {
    padding-left: 1.5rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 0%;
  }

  .dm__storeName {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .dm__dashboard {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 9;
  }
  h3 {
    padding-left: 1.5rem;
  }
  .dm__main {
    background-color: white;
    padding: 1.5rem;
    margin-left: 30px;
  }
  .dm__noneFound {
    text-align: center;
  }

  .dm__filterButton {
    display: none;
  }
  .filter-modal {
    display: none;
  }
}
.ln-c-link {
  display: none;
}
.dm__onlineDevice,
.dm__offlineDevice {
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  line-height: 15px;
  width: 100%;
  background-color: white;
  padding: 1.25rem 1.25rem;
  outline-offset: -8px;
  box-shadow: 0 0 3px #ddd;
}
.dm__deviceCounter {
  padding-bottom: 1rem;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
}
.red__text {
  font-weight: 550;
  color: #cc0000;
}

.dm__deviceName:not(:last-child)::after {
  content: ', ';
}

.dm__offlineDevice {
  box-sizing: border-box;
  border-left: 4px solid #d70000;
}

.dm__noneFound {
  font-size: 2rem;
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  font-weight: 700;
  padding: 1.5rem;
}
