.ln-o-page__header .ln-c-link--dark {
  display: none;
}

.ln-c-header__spacer {
  display: none !important; /* Bug in Luna component, this element should not be rendered. */
}

@media screen and (max-width: 720px) {
  .ln-o-page__header + .ln-o-page__body {
    margin-top: 0px;
  }
}

@media screen and (min-width: 720px) {
  .ln-o-page {
    margin-top: 0px;
    padding-top: 0px !important;
  }
}
.ln-c-header {
  position: sticky;
}