.filter-pill {
  min-height: 28px;
  min-width: 2px;
  padding: 7px 5px 7px 9px;
  border-radius: 10px;
  border: 0.01rem solid;
}
.filter-pill:hover {
  background-color: $ln-color-white;
  border-color: black;
  color: $ln-color-grey-dark;
}
.filter-pill--item {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-family: 'Trebuchet MS', sans-serif;
  background-color: $ln-color-orange;
  color: $ln-color-white;
  border-color: $ln-color-orange;
}
.dm__message-icon {
  color: #0a6ed1;
  width: 80px;
  height: 80px;
}
.dm__message-body {
  padding-left: 25%;
  padding-right: 25%;
}
.dm__reset-filter-button {
  width: 100px;
}

@media screen and (min-width: 960px) {
  .dm__filter-pill-mobile {
    display: none;
  }

  .dm__filter-panel-grid {
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .dm__filter-pill-desktop {
    padding-left: 1.2rem;
    padding-bottom: 1rem;
  }

  .dm__filter-panel {
    position: sticky;
    top: 120px;
    height: 85vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 960px) {
  .dm__filter-pill-desktop {
    display: none;
  }
}

.dm__filter-pill-mobile {
  padding-left: 1.2rem;
  padding-bottom: 1rem;
}

.dm__filter-panel-wrapper {
  background-color: white;
}

// .dm__filter-panel {
//   position: sticky;
//   top: 120px;
//   height: 88vh;
// }
