.utils__rounded__button {
  min-height: 28px;
  min-width: 2px;
  padding: 5px 15px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
}

.utils__rounded__button__container {
  float: right;
  padding-right: 1.5rem;
}

@media screen and (max-width: 480px) {
  .utils__rounded__button__container {
    float: left;
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 960px) {
  .utils__rounded__button {
    font-size: 16px;
  }
}

.text__button {
  text-align: left;
  width: 100%;
}
